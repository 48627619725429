import { set, unset, when } from 'cerebral/factories'
import { props, state } from 'cerebral'
import { send } from '../../socket/actions'
import { POSTRequest } from '../../../utils/api/requestWrapper'
import { getCoreUrl } from '../../../utils/api/apiURLUtil'
import { getState } from '../../../utils/StoreManager'

const sendBannedCountries = [
  // ({ store, get }) => ({
  //   params: {
  //     countries: JSON.stringify(get(state`intl.bannedCountries`))
  //   },
  //   method: 'setBannedCountries'
  // }),
  // send
  ()=>{
    POSTRequest(getCoreUrl('/api/v1/users/banned-countries', true), {
      countries: getState('intl.bannedCountries', {})
    }, (data)=>{
      console.log(data.result)
    })
  }
]

export default [
  when(props`ban`), {
    true: [
      set(state`intl.bannedCountries.${props`country`}`, true),
      sendBannedCountries
    ],
    false: [
      unset(state`intl.bannedCountries.${props`country`}`),
      sendBannedCountries
    ]
  }
]
