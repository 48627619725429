import { state } from 'cerebral'
import { getSessionId, Ws } from '@/utils'
import { getRatingArray } from '@/utils/themeUtils'
import is from 'is_js'
import config from '@/config'
import { setStoreWrapper } from '../../../utils/StoreManager'
import { authGameSocket } from '../../../utils/cardGame/cardGameWSS'
import useDialogsStore from '../../../zustand/useDialogsStore'

const { ftCoreUrl } = config

const getOS = () => {
  try {
    if (is.ios()) {
      const v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/)
      const version = parseInt(v[1], 10)
      return 'ios ' + version
    }
    if (is.android()) {
      const match = navigator.userAgent.toLowerCase().match(/android\s([0-9\.]*)/)
      return 'and ' + parseInt(match[1])
    }
    if (is.mac()) {
      return 'mac'
    }
  } catch (e) {
    console.warn('@@@ getOS error:', e)
  }
  return 'web'
}

export default [
  () => {
    useDialogsStore.getState().loadDialogsPreviews()
    setStoreWrapper('authData', Date.now())
  },
  ({ store, get }) => {
    const ws = Ws()
    const data = {
      method: 'getAuthInfo',
      params: {
        sid: getSessionId(),
        did: get(state`auth.token`),
        ageRating: getRatingArray(get(state`app.ageRating`)),
        lang: get(state`intl.lang`),
        os: getOS(),
        dpi: Math.round(window.devicePixelRatio * 96)
      }
    }
    authGameSocket()
    try {
      setStoreWrapper('tempAD', JSON.stringify(data))
      ws.send(JSON.stringify(data))
      // sendToWS2(JSON.stringify(data), true)
    } catch (e) {
      console.log('cant send auth info', e)
    }
  },
  async ({ store, get }) => {
    try {
      let url = `${ftCoreUrl}/api/v1/sso?sid=${getSessionId()}`
      url += `&domain=${location.hostname}`
      const response = await fetch(url)
      const ssoconf = await response.json()
      store.set('app.ssoconf', ssoconf.ssoData)
      store.set('app.websiteid', ssoconf.websiteid)
    } catch (e) {
      console.log('@@@ load sso error')
    }
  }
]
