import { state } from 'cerebral'
import panelsOnPop from './panelsOnPop'
import { sendAmplitudeEvent } from '@/utils/GAEvent'
import { getUserAgeRating } from '../../../utils/ageRating/ageRatingUtil'
import { POSTRequest } from '../../../utils/api/requestWrapper'
import { getCoreUrl } from '../../../utils/api/apiURLUtil'

export default [
    ({ props, get, store }) => {
        const subs = get(state`auth.subs`)
        if (!subs || !subs[props.uid]) {
            return
        }
        props.type = subs[props.uid].subscriptionType
        delete subs[props.uid]
        store.set('auth.subs', subs)
        POSTRequest(getCoreUrl('/api/v1/subscriptions/unsubscribe'), {
            model: props.uid
        })
    },
    panelsOnPop,
    ({ get, store, props }) => {
        let count = get(state`auth.subsCount`) || 0
        count--
        store.set(state`auth.subsCount`, count)

        sendAmplitudeEvent('subscriptions', {
            action: 'unsubscribe',
            type: props.type.toLowerCase(),
            counterparty_id: props.uid,
            age_rating: getUserAgeRating(props.uid),
            sent_coins: 0
        })
    }
]

